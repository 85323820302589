<template>
  <div class="h-full flex flex-col pb-10">
    <div class="w-full px-6">
      <div class="mt-4 text-white text-3xl font-bold">
        Pump & Dump
      </div>
      <div class="mt-5 border-b border-white/20 pb-8">
        <div class="text-gray-100 text-xl">Pump 🚀</div>
        <fieldset class="mt-2" aria-label="Choose a period">
          <div class="flex items-center justify-between">
            <div class="text-sm/6 text-white/70 flex items-center justify-between w-full">
              Period
              <div class="text-red-600 font-medium">{{ form.pump.period }} min</div>
            </div>
          </div>
          <SingleRangeSlider v-model="form.pump.period" />
        </fieldset>
        <div class="mt-5">
          <div class="text-sm/6 text-white/70">
            Percent growth
          </div>

          <div class="mt-1 relative">
            <div class="absolute right-3 top-2.5 text-base text-white/70">%</div>
            <input
                v-model="form.pump.percent"
                type="number"
                inputmode="decimal"
                step="any"
                class="block w-full rounded-xl custom-bg-gray px-3 py-2 text-base text-white/70 outline outline-1 -outline-offset-1 outline-white/15 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-red-600 ring-0 focus:ring-0 ring-opacity-0 bottom-0"
            />
          </div>
        </div>
      </div>

      <div class="mt-5 border-b border-white/20 pb-8">
        <div class="text-gray-100 text-xl">Dump 🔻</div>
        <fieldset class="mt-2" aria-label="Choose a period">
          <div class="flex items-center justify-between">
            <div class="text-sm/6 text-white/70 flex items-center justify-between w-full">
              Period
              <div class="text-red-600 font-medium">{{ form.dump.period }} min</div>
            </div>
          </div>
          <SingleRangeSlider v-model="form.dump.period" />
        </fieldset>
        <div class="mt-5">
          <div class="text-sm/6 text-white/70">
            Percent decline
          </div>

          <div class="mt-1 relative">
            <div class="absolute right-3 top-2.5 text-base text-white/70">%</div>
            <input
                v-model="form.dump.percent"
                type="number"
                inputmode="decimal"
                step="any"
                class="block w-full rounded-xl custom-bg-gray px-3 py-2 text-base text-white/70 outline outline-1 -outline-offset-1 outline-white/15 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-red-600 ring-0 focus:ring-0 ring-opacity-0 bottom-0"
            />
          </div>
        </div>
      </div>

      <div class="mt-5 mb-8">
        <div class="text-sm/6 text-white/70">Max signal number</div>
        <div class="mt-1">
          <input
              v-model="form.max_signal_number"
              type="number"
              inputmode="decimal"
              step="any"
              class="block w-full rounded-xl custom-bg-gray px-3 py-2 text-base text-white/70 outline outline-1 -outline-offset-1 outline-white/15 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-red-600 ring-0 focus:ring-0 ring-opacity-0 bottom-0"
          />
        </div>
        <div class="text-xs text-white/50 mt-1.5 flex items-center">
          <InformationCircleIcon class="w-3 h-3 mr-1" />
          Number of signals per 24 hours per 1 ticker.
        </div>
      </div>

      <button
          @click="onUpdateChanges"
          type="button"
          class="flex w-full bg-red-600 items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
          :class="[formProcessing ? 'opacity-50 pointer-events-none' : '']"
      >
        <template v-if="formProcessing">
          <BtnSpinner />
        </template>
        Update changes
      </button>

      <div v-if="successMessage.length > 0" class="text-green-500 flex items-center mt-3 text-sm justify-center">
        <CheckCircleIcon class="w-4 h-4 mr-2" />
        {{ successMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import BtnSpinner from "@/components/base/BtnSpinner.vue";
import { InformationCircleIcon, CheckCircleIcon } from '@heroicons/vue/16/solid'
import SingleRangeSlider from '@/components/form/SingleRangeSlider.vue'

export default {
  components: {
    BtnSpinner,
    InformationCircleIcon,
    CheckCircleIcon,
    SingleRangeSlider
  },

  methods: {
    onUpdateChanges() {
      this.formProcessing = true

      this.$api.post('/user/update-settings', this.form)
        .then(() => {
          this.successMessage = 'Settings updated successfully'
        })
        .catch(() => {
          alert('Failed to update settings')
        })
        .finally(() => {
          this.formProcessing = false
        })
    }
  },

  data() {
    return {
      loading: true,
      formProcessing: false,

      successMessage: '',

      form: {
        section: 'pd',
        pump: {
          period: 15,
          percent: 1,
        },
        dump: {
          period: 15,
          percent: 1,
        },
        max_signal_number: 1,
      },
    }
  },

  mounted() {
    this.$api.get('/user').then(response => {
      this.form = {
        section: 'pd',
        pump: {
          period: response.data.pump_period,
          percent: response.data.pump_percent,
        },
        dump: {
          period: response.data.dump_period,
          percent: response.data.dump_percent,
        },
        max_signal_number: response.data.pd_max_signal_number,
      }
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>