<template>
  <div class="h-full flex flex-col">
    <div class="w-full px-6">
      <div class="mt-4 text-white text-3xl font-bold">Notifications</div>

      <div class="rounded-xl bg-white/15 p-3 mt-5 mb-5">
        <div class="flex">
          <div class="shrink-0">
            <InformationCircleIcon class="size-5 text-white/70" aria-hidden="true" />
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-white/70">
              Чтобы получать уведомления, установите соответствующего бота в зависимости от нужного типа уведомлений.
            </p>
          </div>
        </div>
      </div>

      <a v-for="(bot, index) in bots" :key="index" :href="bot.url" target="_blank" class="flex items-center justify-between custom-bg-gray rounded-xl mt-4 px-3 py-3">
        <div v-if="bot.is_subscribed">
          <CheckCircleIcon class="size-7 text-green-500" aria-hidden="true" />
        </div>
        <div v-else>
          <BellSlashIcon class="size-7 text-white/70" aria-hidden="true" />
        </div>
        <div class="w-full pl-3">
          <div class="text-sm" :class="[bot.is_subscribed ? 'text-white/90' : 'text-white/70']">{{ bot.name }}</div>
          <div class="text-white/50 text-xs">
            <span v-if="bot.notification_type === 'pd'">Пампы и дампы</span>
            <span v-if="bot.notification_type === 'oi'">Рост/падение открытого интереса</span>
            <span v-if="bot.notification_type === 'liq'">Ликвидации</span>
          </div>
        </div>
        <div class="opacity-40 text-white flex items-center">
          <ChevronRightIcon class="w-6 h-6" />
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import { InformationCircleIcon, BellSlashIcon, CheckCircleIcon} from '@heroicons/vue/20/solid'
import {ChevronRightIcon} from "@heroicons/vue/24/solid";

export default {
  components: {
    ChevronRightIcon,
    InformationCircleIcon,
    BellSlashIcon,
    CheckCircleIcon,
  },

  methods: {
    onLoadBots() {
      this.$api.get('/notification-bots').then((result) => {
        console.log(result.data)
        this.bots = result.data;
      }).finally(() => {
        this.loading = false;
      })
    },
  },

  data() {
    return {
      loading: true,
      bots: [],
    }
  },

  mounted() {
    this.onLoadBots()
  }
}
</script>
