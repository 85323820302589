<template>
  <div class="h-full flex flex-col pb-20">
    <div class="w-full px-4">
      <div class="mt-4 text-white text-3xl font-bold">Liquidations</div>

      <template v-if="loading">
        <div class="mt-5">
          <LoadingBlock w="w-6" h="h-6" />
        </div>
      </template>
      <template v-else>
        <div class="text-white mt-5 space-y-3">
          <div
              v-for="liquidation in liquidations"
              :key="liquidation.id"
              class="bg-white/15 rounded-xl p-3 mb-3 flex items-center justify-between"
          >
            <div class="flex items-center w-full">
              <div class="flex shrink items-center relative">
                <SymbolIcon :currency="liquidation.symbol" size="32" />
              </div>
              <div class="pl-3">
                <a target="_blank" class="text-sm text-white/90 flex items-center">
                  <img :src="`/img/exchange/${liquidation.exchange_slug}.png`" class="w-4 h-4 mr-1 rounded-full" />
                  {{ liquidation.symbol }}
                </a>
                <div class="text-xs">
                  <span
                      :class="{
                    'text-green-500': liquidation.side === 'sell',
                    'text-red-500': liquidation.side === 'buy'
                  }"
                      class="font-semibold"
                  >
                  {{ liquidation.side === 'sell' ? 'Long' : 'Short' }}
                </span>
                </div>
              </div>
            </div>
            <div class="flex flex-col flex-end text-right whitespace-nowrap">
              <div v-if="liquidation.side === 'buy'" class="font-medium text-green-500">
                ${{ theNumber(liquidation.amount, '0,0.00') }}
              </div>
              <div v-if="liquidation.side === 'sell'" class="font-medium text-red-500">
                ${{ theNumber(liquidation.amount, '0,0.00') }}
              </div>
              <div class="text-xs text-white/50">
                {{ theNumber(liquidation.quantity, '0,0.00') }} {{ liquidation.symbol.replace("USDT", "") }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import LoadingBlock from '@/components/base/LoadingBlock.vue'
import SymbolIcon from "@/components/icons/SymbolIcon.vue";

export default {
  components: {
    SymbolIcon,
    LoadingBlock,
  },

  data() {
    return {
      loading: true,
      liquidations: [],
      lastId: 0,
      pollingTimer: null,
      abortController: null,
    }
  },

  methods: {
    theNumber(amount, format = '0,0.0000') {
      return numeral(amount).format(format)
    },

    formatDate(timestamp) {
      const date = new Date(Number(timestamp))
      const day = String(date.getUTCDate()).padStart(2, '0')
      const month = String(date.getUTCMonth() + 1).padStart(2, '0')
      const year = date.getUTCFullYear()
      const hours = String(date.getUTCHours()).padStart(2, '0')
      const minutes = String(date.getUTCMinutes()).padStart(2, '0')

      return `${day}/${month}/${year} ${hours}:${minutes}`
    },

    async loadLiquidation() {
      try {
        this.abortController = new AbortController()

        const response = await this.$api.get('/liquidations', {
          params: { last_id: this.lastId },
          signal: this.abortController.signal
        })

        if (response.data.data.length > 0) {
          // Добавляем новые записи в начало списка
          this.liquidations = [
            ...response.data.data.reverse(),
            ...this.liquidations
          ].slice(0, 100)

          this.lastId = response.data.last_id
        }

        this.loading = false
        this.scheduleNextPoll()

      } catch (e) {
        if (e.name !== 'CanceledError') {
          console.error('Liquidation polling error:', e)
          this.scheduleNextPoll(10000)
        }
      }
    },

    scheduleNextPoll(delay = 3000) {
      this.pollingTimer = setTimeout(() => {
        this.loadLiquidation()
      }, delay)
    },

    cancelPolling() {
      if (this.abortController) {
        this.abortController.abort()
      }
      clearTimeout(this.pollingTimer)
    }
  },

  mounted() {
    this.loadLiquidation()
  },

  beforeUnmount() {
    this.cancelPolling()
  }
}
</script>
