<template>
  <div class="crypto-icon" :style="iconStyle">
    <img
        v-if="svgPath"
        :src="svgPath"
        :alt="`${baseCurrency} cryptocurrency icon`"
        class="crypto-icon__image"
        :class="{ 'crypto-icon__image--loading': !isLoaded }"
        @load="handleImageLoad"
    />
    <div v-else class="crypto-icon__fallback">
      {{ baseCurrencyDisplay }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CryptocurrencyIcon',

  props: {
    currency: {
      type: String,
      required: true,
    },
    size: {
      type: [Number, String],
      default: 32,
    },
    shadow: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoaded: false,
      svgPath: null
    };
  },

  computed: {
    baseCurrency() {
      return this.currency.replace(/usdt$/i, '').toLowerCase();
    },

    baseCurrencyDisplay() {
      return this.baseCurrency.slice(0, 4).toUpperCase();
    },

    iconStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        filter: this.shadow ? 'drop-shadow(0 2px 4px rgba(0,0,0,0.25))' : null
      };
    }
  },

  watch: {
    currency: {
      immediate: true,
      handler() {
        this.loadIcon();
      }
    }
  },

  methods: {
    async loadIcon() {
      this.isLoaded = false;
      try {
        const iconModule = await import(
            /* webpackMode: "eager" */
            `cryptocurrency-icons/svg/color/${this.baseCurrency}.svg`
            );
        this.svgPath = iconModule.default;
      } catch (e) {
        console.warn(`Icon for ${this.baseCurrency} not found`);
        const fallbackUrl = `https://cryptofonts.com/img/SVG/${this.baseCurrency}.svg`;

        // Проверяем доступность резервной иконки
        const isAvailable = await this.checkImage(fallbackUrl);

        if (isAvailable) {
          this.svgPath = fallbackUrl;
        } else {
          console.warn(`Fallback icon for ${this.baseCurrency} also not available`);
          this.svgPath = null;
        }
      }
      this.isLoaded = true;
    },

    checkImage(url) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = url + '?t=' + Date.now();
      });
    },

    handleImageLoad() {
      this.isLoaded = true;
      this.$emit('loaded');
    }
  }
};
</script>

<style scoped>
/* Стили остаются без изменений */
.crypto-icon {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  background: #ffffff;
  border-radius: 35px;
  overflow: hidden;
}

.crypto-icon__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.crypto-icon__image--loading {
  opacity: 0;
}

.crypto-icon__fallback {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(240, 242, 245, 0.93);
  border-radius: 6px;
  font-family: 'Segoe UI', system-ui, sans-serif;
  font-size: 0.6em;
  font-weight: 700;
  color: #7a8c9e;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
</style>