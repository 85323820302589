<template>
  <div class="h-full flex flex-col pb-20">
    <div class="w-full px-4">
      <div class="mt-4 text-white text-2xl font-bold">
        Analyze
      </div>

      <template v-if="!analyzeSignal || analyzeSignal.status === 'pending'">
        <div class="mt-5">
          <LoadingBlock w="w-6" h="h-6" message="AI assistant analyzes the signal..." />
        </div>
      </template>
      <template v-else>
        <div class="mt-5">
          <div v-if="analyzeSignal.status === 'completed'">
            <div class="text-white/85 bg-white/15 rounded-xl p-3 mb-3 text-sm">
              <div class="grid grid-cols-2 border-b border-white/15 pb-2 mb-3">
                <div class="text-white/50">
                  {{ typeTitleMap[analyzeSignal.data.analyze_data.type] }}
                </div>
                <div :class="[analyzeSignal.data.analyze_data.type === 'pump' ? 'text-green-500' : 'text-red-600']">
                  {{ analyzeSignal.data.analyze_data.percentage_change }}%
                </div>
              </div>
              <div class="grid grid-cols-2 border-b border-white/15 pb-2 mb-3">
                <div class="text-white/50">
                  Symbol
                </div>
                <div>
                  {{ analyzeSignal.data.analyze_data.symbol }}
                </div>
              </div>
              <div class="grid grid-cols-2 border-b border-white/15 pb-2 mb-3">
                <div class="text-white/50">
                  Exchange
                </div>
                <div class="flex items-center">
                  <img :src="`/img/exchange/${analyzeSignal.data.analyze_data.exchange_name}.png`" class="w-6 h-6 rounded-full mr-2" />
                  {{ exchangeNameMap[analyzeSignal.data.analyze_data.exchange_name] }}
                </div>
              </div>
              <div class="grid grid-cols-2 border-b border-white/15 pb-2 mb-3">
                <div class="text-white/50">
                  Change price
                </div>
                <div>
                  ${{ theNumber(analyzeSignal.data.analyze_data.start_price) }} -> ${{ theNumber(analyzeSignal.data.analyze_data.end_price) }}
                </div>
              </div>
              <div class="grid grid-cols-2 border-b border-white/15 pb-2 mb-3">
                <div class="text-white/50">
                  Open Interest
                </div>
                <div :class="[analyzeSignal.data.analyze_data.open_interest.change_type === 'growth' ? 'text-green-500' : 'text-red-600']">
                  {{ analyzeSignal.data.analyze_data.open_interest.percent_change }}%
                </div>
              </div>
              <div class="grid grid-cols-2">
                <div class="text-white/50">
                  Liquidations
                </div>
                <div>
                  <div>${{ theNumber(analyzeSignal.data.analyze_data.liquidations.total) }}</div>
                </div>
              </div>
            </div>
            <div class="text-white/85 bg-white/15 rounded-xl p-3 mb-3 text-sm prose">
              <div class="flex items-center w-full text-blue-600 mb-2">
                <InformationCircleIcon class="w-5 h-5 inline-block -mt-1 mr-1" /> Description
              </div>
              {{ analyzeSignal.data.description }}
            </div>
            <div class="text-white/85 bg-white/15 rounded-xl p-3 mb-3 text-sm prose">
              <div class="flex items-center w-full text-green-500 mb-2">
                <ArrowTrendingUpIcon class="w-5 h-5 inline-block -mt-1 mr-1" /> Forecast
              </div>
              {{ analyzeSignal.data.forecast }}
            </div>
            <div class="text-white/85 bg-white/15 rounded-xl p-3 mb-3 text-sm prose">
              <div class="flex items-center w-full text-purple-600 mb-2">
                <BoltIcon class="w-5 h-5 inline-block -mt-1 mr-1" /> Recommendation
              </div>
              {{ analyzeSignal.data.recommendation }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import LoadingBlock from '@/components/base/LoadingBlock.vue'
import {InformationCircleIcon, ArrowTrendingUpIcon, BoltIcon} from '@heroicons/vue/24/solid'

export default {
  components: {
    LoadingBlock,
    InformationCircleIcon,
    ArrowTrendingUpIcon,
    BoltIcon,
  },

  data() {
    return {
      analyzeSignal: null,      // Holds the API response data
      pollInterval: null,       // Stores the interval ID for polling
      activeIndex: 0,          // Retained from original code (purpose unclear here)

      typeTitleMap: {
        pump: 'Pump',
        dump: 'Dump',
      },

      exchangeNameMap: {
        bybit: 'Bybit',
        binance: 'Binance',
      },
    }
  },

  methods: {
    theNumber(amount, format = '0,0.0000') {
      return numeral(amount).format(format)
    },

    loadAnalyzeSignal() {
      const id = this.$route.params.id

      this.$api.get('/analyze-signals/' + id)
          .then(response => {
            console.log(response) // For debugging; can be removed later
            this.analyzeSignal = response.data

            // If status is 'pending', start polling if not already started
            if (this.analyzeSignal.status === 'pending') {
              if (!this.pollInterval) {
                this.pollInterval = setInterval(() => {
                  this.loadAnalyzeSignal()
                }, 3000)
              }
            }
            // If status is not 'pending', stop polling if active
            else {
              if (this.pollInterval) {
                clearInterval(this.pollInterval)
                this.pollInterval = null
              }
            }
          })
          .catch(() => {
            alert('Failed to load') // Basic error handling; consider UI feedback instead
            if (this.pollInterval) {
              clearInterval(this.pollInterval)
              this.pollInterval = null
            }
          })
    },
  },

  mounted() {
    // Initiate the first API call when the component mounts
    this.loadAnalyzeSignal()
  },

  beforeUnmount() {
    // Clean up the interval to prevent memory leaks
    if (this.pollInterval) {
      clearInterval(this.pollInterval)
      this.pollInterval = null
    }
  },
}
</script>
