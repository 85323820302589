<template>
  <div class="h-full flex flex-col">
    <div class="w-full px-6">
      <div class="mt-4 text-white text-3xl font-bold">
        Filters
      </div>

      <div class="mt-5">
        <div class="text-white/70 mb-2">Max signal number</div>
        <div>
          <input
              v-model="form.max_signal_number"
              type="number"
              inputmode="decimal"
              step="any"
              class="block w-full rounded-xl custom-bg-gray px-3 py-2 text-base text-white/70 outline outline-1 -outline-offset-1 outline-white/15 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-red-600 ring-0 focus:ring-0 ring-opacity-0 bottom-0"
          />
        </div>
      </div>

      <div class="mt-5">
        <div class="text-white/70 mb-2">Type signal</div>
        <div class="custom-bg-gray rounded-xl px-3">
          <div class="py-2.5">
            <ToggleButton
                v-model="form.buy_signal"
                label="Buy signal"
            />
          </div>
          <div class="py-2.5 border-t border-white/10">
            <ToggleButton
                v-model="form.sell_signal"
                label="Sell signal"
            />
          </div>
        </div>
      </div>

      <div class="mt-5">
        <div class="text-white/70 mb-2">RSI</div>

        <div class="py-2.5 custom-bg-gray rounded-xl px-3">
          <RangeSlider
              v-model="form.roi"
              :min="0"
              :max="100"
              :step="1"
              label="Select RSI range"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@/components/form/ToggleButton'
import RangeSlider from '@/components/form/RangeSlider.vue'

export default {
  components: {
    ToggleButton,
    RangeSlider
  },

  data() {
    return {
      form: {
        buy_signal: true,
        sell_signal: true,
        max_signal_number: 10,
        roi: [50, 100]
      }
    }
  },

  mounted() {
  }
}
</script>