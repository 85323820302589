<template>
  <div class="h-full flex flex-col pb-20">
    <div class="w-full px-6">
      <div class="mt-4 text-white text-3xl font-bold">Last analyzes</div>
      <div class="text-white/45">Your latest AI signal analysis</div>
    </div>
    <template v-if="loading">
      <div class="mt-5 px-6">
        <LoadingBlock w="w-6" h="h-6" />
      </div>
    </template>
    <template v-else>
      <div class="mt-5 px-6">
        <div v-for="(analyzeSignal, analyzeSignalIdx) in analyzeSignals" :key="analyzeSignalIdx" class="bg-white/10 rounded-xl p-3 mb-3 flex flex-col">
          <div class="flex items-center justify-between w-full">
            <div
                class="text-white text-xs px-2 inline-flex rounded-lg"
                :class="[
              analyzeSignal.status === 'pending' ? 'bg-yellow-500' : '',
              analyzeSignal.status === 'completed' ? 'bg-green-500' : '',
              analyzeSignal.status === 'failed' ? 'bg-red-500' : ''
            ]"
            >
              {{ analyzeSignal.status }}
            </div>
            <div class="text-xs text-white/30">{{ formatDate(analyzeSignal.updated_at) }}</div>
          </div>
          <div>
            <template v-if="analyzeSignal.status === 'completed'">
              <div class="text-white/30 text-sm pt-3">
                {{ truncate(analyzeSignal.data.description, 100) }}
              </div>
              <div>
                <router-link :to="`/analyze/${analyzeSignal.id}`" class="text-white/50 inline-flex items-center mt-2">
                  View detail <ChevronRightIcon class="mr-2 w-5 h-5" />
                </router-link>
              </div>
            </template>
            <template v-if="analyzeSignal.status === 'pending'">
              <div class="text-white/30 text-sm pt-3">
                AI assistant analyzes the signal...
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingBlock from '@/components/base/LoadingBlock.vue'
import {ChevronRightIcon} from "@heroicons/vue/24/solid";

export default {
  name: 'HomeView',

  components: {
    LoadingBlock,
    ChevronRightIcon,
  },

  methods: {
    truncate(str, maxLength) {
      return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
    },
    formatDate(isoString) {
      const date = new Date(isoString);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
    onLoadAnalyzeSignals() {
      this.$api.get('/analyze-signals').then((result) => {
        console.log(result)
        this.analyzeSignals = result.data.data;
      }).finally(() => {
        this.loading = false
      })
    }
  },

  data() {
    return {
      loading: true,
      signals: null,
      analyzeSignals: [],
    }
  },

  mounted() {
    this.onLoadAnalyzeSignals();
  }
}
</script>
