<template>
  <div class="min-h-full-screen relative">
    <router-view/>
    <div class="fixed z-40 bottom-0 left-0 w-full bg-bav-color border-t border-white/10">
      <nav class="w-full grid grid-cols-4 text-white/65">
        <router-link
          to="/"
          :class="[
            'nav-item flex items-center flex-col justify-center py-2 relative',
            isActiveMenuItem('home') && 'nav-active text-white'
          ]"
        >
          <SignalIcon class="w-6 h-6" />
          <span class="text-xs">Signals</span>
        </router-link>
        <router-link
            to="/pump"
            :class="[
            'nav-item flex items-center flex-col justify-center py-2 relative',
            isActiveMenuItem('pump') && 'nav-active text-white'
          ]"
        >
          <ArrowsUpDownIcon class="w-6 h-6" />
          <span class="text-xs">Pump & Dump</span>
        </router-link>
        <router-link
            to="/oi"
            :class="[
            'nav-item flex items-center flex-col justify-center py-2 relative',
            isActiveMenuItem('oi') && 'nav-active text-white'
          ]"
        >
          <ChartBarIcon class="w-6 h-6" />
          <span class="text-xs">Open Interest</span>
        </router-link>
        <router-link
          to="/liquidations"
          :class="[
            'nav-item flex items-center flex-col justify-center py-2 relative',
            isActiveMenuItem('liquidations') && 'nav-active text-white'
          ]"
        >
          <BoltIcon class="w-6 h-6" />
          <span class="text-xs">Liquidations</span>
        </router-link>
      </nav>
    </div>
  </div>
</template>
<script>
import {SignalIcon, BoltIcon, ArrowsUpDownIcon, ChartBarIcon} from '@heroicons/vue/24/solid'

export default {
  components: {
    SignalIcon,
    BoltIcon,
    ArrowsUpDownIcon,
    ChartBarIcon
  },

  methods: {
    isActiveMenuItem(key) {
      if (key === 'home' && this.$route.fullPath === '/') {
        return true
      }

      return this.$route.fullPath.includes(key)
    }
  },

  mounted() {
    console.log(this.$route)

    if (window.Telegram && window.Telegram.WebApp) {
      this.telegramReady = true

      if (window.Telegram.WebApp.isVersionAtLeast('7.0')) {
        window.Telegram.WebApp.SettingsButton.show();

        window.Telegram.WebApp.SettingsButton.onClick(() => {
          this.$router.push({ name: 'settings' });
        });
      }
    }
  }
}
</script>
