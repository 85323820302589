<template>
  <SwitchGroup as="div" class="flex items-center justify-between">
    <span class="flex grow flex-col">
      <SwitchLabel
          as="span"
          class="text-sm/6 font-medium text-white/70"
          passive
      >
        {{ label }}
      </SwitchLabel>
      <SwitchDescription v-if="description && description.length > 0" as="span" class="text-sm text-white/45">
        {{ description }}
      </SwitchDescription>
    </span>
    <Switch
        :modelValue="modelValue"
        @update:modelValue="updateValue"
        :class="switchClasses"
        :aria-checked="modelValue.toString()"
    >
      <span
          aria-hidden="true"
          :class="indicatorClasses"
      />
    </Switch>
  </SwitchGroup>
</template>

<script>
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export default {
  name: "ToggleButton",
  components: {
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  emits: ["update:modelValue"],
  methods: {
    updateValue(value) {
      this.$emit("update:modelValue", value);
    },
  },
  computed: {
    switchClasses() {
      return (
          (this.modelValue ? "bg-red-600" : "bg-white/25") +
          " relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
      );
    },
    indicatorClasses() {
      return (
          (this.modelValue ? "translate-x-5" : "translate-x-0") +
          " pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
      );
    },
  },
};
</script>
