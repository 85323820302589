<template>
  <div class="w-full">
    <!-- Метка и описание (опционально) -->
    <label v-if="label" class="block text-sm text-white/70">
      {{ label }}
    </label>

    <!-- Контейнер слайдера -->
    <div
        class="relative mt-4"
        ref="slider"
        @mousedown.prevent
        @touchstart.prevent
    >
      <!-- Фон трека -->
      <div class="absolute inset-0 flex items-center">
        <div class="h-2 w-full bg-white/25 rounded-full"></div>
        <!-- Выбранный диапазон -->
        <div
            class="h-2 bg-indigo-600 rounded-full"
            :style="rangeStyle"
        ></div>
      </div>

      <!-- Нижний ползунок -->
      <div
          class="absolute top-1 -mt-1 w-5 h-5 bg-red-600 rounded-full cursor-pointer shadow focus:outline-none focus:ring-2 focus:ring-indigo-600"
          :style="thumbStyle(lowerValue)"
          @mousedown="startDrag('lower', $event)"
          @touchstart="startDrag('lower', $event)"
          @keydown="onKeyDown('lower', $event)"
          tabindex="0"
          role="slider"
          :aria-valuemin="min"
          :aria-valuemax="upperValue - step"
          :aria-valuenow="lowerValue"
          :aria-label="`Минимальное значение: ${lowerValue}`"
      ></div>

      <!-- Верхний ползунок -->
      <div
          class="absolute top-1 -mt-1 w-5 h-5 bg-red-600 rounded-full cursor-pointer shadow focus:outline-none focus:ring-2 focus:ring-indigo-600"
          :style="thumbStyle(upperValue)"
          @mousedown="startDrag('upper', $event)"
          @touchstart="startDrag('upper', $event)"
          @keydown="onKeyDown('upper', $event)"
          tabindex="0"
          role="slider"
          :aria-valuemin="lowerValue + step"
          :aria-valuemax="max"
          :aria-valuenow="upperValue"
          :aria-label="`Максимальное значение: ${upperValue}`"
      ></div>
    </div>

    <!-- Отображение выбранных значений -->
    <div class="flex justify-between mt-2 text-sm text-white/70 mt-7">
      <span>{{ lowerValue }}</span>
      <span>{{ upperValue }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RangeSlider",
  props: {
    /**
     * Массив из двух чисел [минимум, максимум]
     */
    modelValue: {
      type: Array,
      required: true,
      validator: (value) =>
          Array.isArray(value) &&
          value.length === 2 &&
          typeof value[0] === "number" &&
          typeof value[1] === "number",
    },
    /**
     * Минимальное значение диапазона
     */
    min: {
      type: Number,
      default: 0,
    },
    /**
     * Максимальное значение диапазона
     */
    max: {
      type: Number,
      default: 100,
    },
    /**
     * Шаг изменения значения
     */
    step: {
      type: Number,
      default: 1,
    },
    /**
     * Метка для слайдера (опционально)
     */
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      lowerValue: this.modelValue[0],
      upperValue: this.modelValue[1],
      dragging: null, // 'lower' или 'upper'
    };
  },
  computed: {
    /**
     * Вычисляет стиль для выбранного диапазона на треке
     */
    rangeStyle() {
      const left = ((this.lowerValue - this.min) / (this.max - this.min)) * 100;
      const right =
          100 - ((this.upperValue - this.min) / (this.max - this.min)) * 100;
      return `left: ${left}%; right: ${right}%;`;
    },
  },
  methods: {
    /**
     * Обработчик начала перетаскивания ползунка
     * @param {String} thumb - Какой ползунок перетаскивается ('lower' или 'upper')
     * @param {Event} event - Событие мыши или сенсорное событие
     */
    startDrag(thumb, event) {
      console.log(event);

      this.dragging = thumb;
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.stopDrag);
      document.addEventListener("touchmove", this.onDrag);
      document.addEventListener("touchend", this.stopDrag);
    },
    /**
     * Обработчик события перетаскивания
     * @param {MouseEvent|TouchEvent} event
     */
    onDrag(event) {
      if (!this.dragging) return;

      const slider = this.$refs.slider;
      const rect = slider.getBoundingClientRect();

      // Определяем положение курсора
      let clientX;
      if (event.type.startsWith("touch")) {
        clientX = event.touches[0].clientX;
      } else {
        clientX = event.clientX;
      }

      // Вычисляем процентное положение курсора относительно трека
      let percent = ((clientX - rect.left) / rect.width) * 100;
      percent = Math.min(Math.max(percent, 0), 100);

      // Преобразуем процент в значение
      let value = Math.round(
          (percent / 100) * (this.max - this.min) + this.min
      );

      // Корректируем значение с учетом шага
      value = Math.round(value / this.step) * this.step;

      if (this.dragging === "lower") {
        this.lowerValue = Math.min(
            Math.max(this.min, value),
            this.upperValue - this.step
        );
      } else if (this.dragging === "upper") {
        this.upperValue = Math.max(
            Math.min(this.max, value),
            this.lowerValue + this.step
        );
      }

      this.emitValues();
    },
    /**
     * Остановка перетаскивания
     */
    stopDrag() {
      this.dragging = null;
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.stopDrag);
      document.removeEventListener("touchmove", this.onDrag);
      document.removeEventListener("touchend", this.stopDrag);
    },
    /**
     * Обработчик нажатий клавиш для ползунков
     * @param {String} thumb - 'lower' или 'upper'
     * @param {KeyboardEvent} event
     */
    onKeyDown(thumb, event) {
      let value;
      if (thumb === "lower") {
        value = this.lowerValue;
      } else if (thumb === "upper") {
        value = this.upperValue;
      }

      switch (event.key) {
        case "ArrowLeft":
        case "ArrowDown":
          value = Math.max(this.min, value - this.step);
          break;
        case "ArrowRight":
        case "ArrowUp":
          value = Math.min(this.max, value + this.step);
          break;
        case "Home":
          value = this.min;
          break;
        case "End":
          value = this.max;
          break;
        default:
          return;
      }

      event.preventDefault();

      if (thumb === "lower") {
        this.lowerValue = Math.min(value, this.upperValue - this.step);
      } else if (thumb === "upper") {
        this.upperValue = Math.max(value, this.lowerValue + this.step);
      }
      this.emitValues();
    },
    /**
     * Эмитирует обновленные значения вверх по цепочке
     */
    emitValues() {
      this.$emit("update:modelValue", [this.lowerValue, this.upperValue]);
    },
    /**
     * Вычисляет стиль для позиционирования ползунка
     * @param {Number} value - Текущее значение ползунка
     */
    thumbStyle(value) {
      const percent = ((value - this.min) / (this.max - this.min)) * 100;
      return `left: ${percent}%; transform: translate(-50%, -50%);`;
    },
  },
  watch: {
    /**
     * Следит за изменениями modelValue и обновляет локальные значения
     */
    modelValue(newVal) {
      this.lowerValue = newVal[0];
      this.upperValue = newVal[1];
    },
    /**
     * Следит за изменениями min и max, чтобы корректировать значения ползунков
     */
    min(newMin) {
      if (this.lowerValue < newMin) {
        this.lowerValue = newMin;
        this.emitValues();
      }
      if (this.upperValue < newMin) {
        this.upperValue = newMin + this.step;
        this.emitValues();
      }
    },
    max(newMax) {
      if (this.upperValue > newMax) {
        this.upperValue = newMax;
        this.emitValues();
      }
      if (this.lowerValue > newMax - this.step) {
        this.lowerValue = newMax - this.step;
        this.emitValues();
      }
    },
  },
  beforeUnmount() {
    // Убираем обработчики событий, если компонент размонтирован
    document.removeEventListener("mousemove", this.onDrag);
    document.removeEventListener("mouseup", this.stopDrag);
    document.removeEventListener("touchmove", this.onDrag);
    document.removeEventListener("touchend", this.stopDrag);
  },
};
</script>

<style scoped>
/* Скрываем стандартные стили ползунков браузера */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
}
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
}

/* Добавление фокуса для видимых ползунков */
div[role="slider"]:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.5); /* Tailwind's indigo-500 with opacity */
}
</style>
