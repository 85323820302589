<template>
  <div class="h-full flex flex-col pb-20">
    <div class="w-full px-3">
      <div class="mt-4 text-white text-3xl font-bold">Open Interest</div>

      <div class="flex items-center mt-5 mb-5 text-sm">
        <button
            @click="onToggleExchange('binance')"
            class="flex items-center bg-white/15 p-1 rounded-lg px-3 py-1.5 mr-3"
            :class="[exchanges.is_oi_binance ? 'text-white/80' : 'text-white/30']"
        >
          <img
              src="/img/exchange/binance.png"
              class="w-6 h-6 mr-2 rounded-full"
              :class="[exchanges.is_oi_binance ? '' : 'opacity-60']"
          /> Binance
        </button>
        <button
            @click="onToggleExchange('bybit')"
            class="flex items-center bg-white/15 p-1 rounded-lg px-3 py-1.5"
            :class="[exchanges.is_oi_bybit ? 'text-white/80' : 'text-white/30']"
        >
          <img
              src="/img/exchange/bybit.png"
              class="w-6 h-6 mr-2 rounded-full"
              :class="[exchanges.is_oi_bybit ? '' : 'opacity-60']"
          /> Bybit
        </button>
      </div>

      <template v-if="loading">
        <div class="mt-5">
          <LoadingBlock w="w-6" h="h-6" />
        </div>
      </template>
      <template v-else>
        <div class="text-white mt-5">
          <template v-if="openInterest.length > 0">
            <div v-for="(item, index) in openInterest" :key="index" class="py-2" :class="[index+1 !== openInterest.length ? 'border-b border-white/15' : '']">
              <div class="flex items-center gap-2">
                <div class="flex-shrink-0 w-7">
                  <button
                      class="bg-white/10 text-white/50 p-1 rounded-lg"
                      :class="[activeIndex === index ? 'text-white/90' : 'text-white/50']"
                  >
                    <ChevronDownIcon v-if="activeIndex !== index" class="w-5 h-5" />
                    <ChevronUpIcon v-else class="w-5 h-5" />
                  </button>
                </div>
                <a href="" target="_blank" class="flex items-center gap-2 flex-grow min-w-0">
                  <div class="relative flex-shrink-0">
                    <div class="absolute -top-1 left-5 w-4 h-4 bg-red-600 text-white z-10 text-xs flex items-center justify-center rounded-xl">{{ item.signal_number }}</div>
                    <SymbolIcon :currency="item.symbol" size="32" />
                  </div>
                  <div class="pl-2 min-w-0">
                    <div class="text-sm text-white/90 flex items-center">
                      <img :src="`/img/exchange/${item.exchange_slug}.png`" class="w-4 h-4 mr-1 rounded-full" />
                      {{ item.symbol }} <ArrowTopRightOnSquareIcon class="ml-1 w-4 h-4 relative -top-0.5" />
                    </div>
                    <div class="text-xs text-white/50 whitespace-nowrap">{{ formatDate(item.created_at) }}</div>
                  </div>
                </a>
                <div class="flex-shrink-0 flex items-center gap-3">
                  <div class="text-right text-sm w-36 whitespace-nowrap">
                    <div v-if="item.status === 'growth'" class="text-green-500 font-bold">
                      +{{ theNumber(item.percentage_change, '0,0.00') }}%
                    </div>
                    <div v-if="item.status === 'decline'" class="text-red-600 font-bold">
                      -{{ theNumber(item.percentage_change, '0,0.00') }}%
                    </div>
                    <div class="text-right text-xs">
                      <div v-if="item.status === 'growth'">
                        <span class="text-white/40">{{ theNumber(item.absolute_change, '0,0') }} {{ item.symbol.replace("USDT", "") }}</span> 🚀
                      </div>
                      <div v-if="item.status === 'decline'">
                        <span class="text-white/40">{{ theNumber(item.absolute_change, '0,0') }} {{ item.symbol.replace("USDT", "") }}</span> 🔻
                      </div>
                    </div>
                  </div>
                  <div class="flex-shrink-0">
                    <Menu as="div" class="relative inline-block text-left">
                      <div>
                        <MenuButton class="flex items-center bg-white/10 text-white/50 p-1 rounded-lg focus:outline-none">
                          <span class="sr-only">Open options</span>
                          <EllipsisVerticalIcon class="size-5" aria-hidden="true" />
                        </MenuButton>
                      </div>

                      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                        <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-zinc-800 shadow-lg ring-1 ring-black/5 focus:outline-none">
                          <div class="py-1">
                            <MenuItem v-slot="{ active }">
                              <a :href="theCoinGlassLink(item.symbol, item.exchange_slug)" target="_blank" :class="[active ? 'text-gray-300 outline-none' : 'text-gray-300', 'flex items-center px-4 py-2 text-sm']">
                                <ArrowTopRightOnSquareIcon class="w-5 h-5 mr-2" aria-hidden="true" />
                                View on Coinglass
                              </a>
                            </MenuItem>
                            <MenuItem v-slot="{ active }">
                              <a href="#" :class="[active ? 'text-gray-300 outline-none' : 'text-gray-300', 'flex items-center px-4 py-2 text-sm']">
                                <BoltIcon class="w-5 h-5 mr-2 text-purple-600" aria-hidden="true" />
                                Analyze AI
                              </a>
                            </MenuItem>
                          </div>
                        </MenuItems>
                      </transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div>
              <div class="text-white/50">
                No increase or decrease in open interest detected based on your settings.
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import LoadingBlock from '@/components/base/LoadingBlock.vue'
import SymbolIcon from '@/components/icons/SymbolIcon.vue'
import {
  ArrowTopRightOnSquareIcon,
  BoltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  EllipsisVerticalIcon
} from "@heroicons/vue/24/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

export default {
  components: {
    EllipsisVerticalIcon,
    BoltIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    ArrowTopRightOnSquareIcon,
    LoadingBlock,
    SymbolIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },

  methods: {
    theCoinGlassLink(symbol, exchangeSlug) {
      if (exchangeSlug === 'binance') {
        return `https://www.coinglass.com/tv/ru/Binance_${symbol}`;
      } else if (exchangeSlug === 'bybit') {
        return `https://www.coinglass.com/tv/ru/Bybit_${symbol}`;
      }
    },

    theNumber(amount, format = '0,0.0000') {
      return numeral(amount).format(format)
    },

    formatDate(isoString) {
      const date = new Date(isoString);
      // Используем UTC-методы, чтобы сохранить время как в исходной строке
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },

    formatTime(isoString) {
      const date = new Date(isoString);
      // Используем UTC-методы, чтобы сохранить время как в исходной строке
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
    },

    onToggleExchange(exchange) {
      if (exchange === 'binance') {
        this.exchanges.is_oi_binance = !this.exchanges.is_oi_binance
      } else if (exchange === 'bybit') {
        this.exchanges.is_oi_bybit = !this.exchanges.is_oi_bybit
      }

      this.loading = true;

      this.$api.post(`/user/update-user-settings`, {
        is_oi_binance: this.exchanges.is_oi_binance,
        is_oi_bybit: this.exchanges.is_oi_bybit,
      }).then(() => {
        this.loadOpenInterest()
      }).catch(() => {
        alert('Failed to update user settings')
      })

      this.loadOpenInterest()
    },

    loadOpenInterest() {
      this.$api.get('/open-interest').then(response => {
        console.log(response.data)
        this.openInterest = response.data
      }).catch((e) => {
        console.log('Failed to load open interest data', e)
      }).finally(() => {
        this.loading = false
      })
    },
  },

  mounted() {
    this.loadOpenInterest()
    this.intervalId = setInterval(() => {
      this.loadOpenInterest()
    }, 10000)

    if (this.$user.settings) {
      this.exchanges.is_oi_binance = this.$user.settings.is_oi_binance
      this.exchanges.is_oi_bybit = this.$user.settings.is_oi_bybit
    }
  },

  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },

  data() {
    return {
      loading: true,
      loadingGroup: true,
      intervalId: null,
      openInterest: [],
      activeIndex: null,

      exchangeNameMap: {
        binance: 'Binance',
        bybit: 'Bybit',
      },

      exchanges: {
        is_oi_binance: false,
        is_oi_bybit: false,
      }
    }
  }
}
</script>
