<template>
  <div class="h-full flex flex-col">
    <div class="w-full px-6">
      <div class="mt-4 text-white text-3xl font-bold">Language</div>

      <div class="custom-bg-gray rounded-xl mt-5 px-3">
        <button v-for="(language, index) in languages" :key="index" @click="onChangeLanguage(language)" class="w-full flex items-center py-2 flex-shrink-0" :class="[index !== 0 && 'border-t border-white/10']">
          <div class="w-7 h-7 rounded-full text-white flex items-center justify-center flex-shrink-0">
            <img :src="`/img/flags/${language.language_code}.png`" class="w-7 h-7 rounded-full" />
          </div>
          <div class="text-white/75 flex items-center justify-between w-full text-sm">
            <div class="pl-3 w-full text-left">
              {{ language.title }}
              <div class="text-xs opacity-50">{{ language.native_title }}</div>
            </div>
            <div class="flex items-center">
              <template v-if="language.language_code === $user.language_code">
                <CheckCircleIcon class="w-5 text-red-600" />
              </template>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {CheckCircleIcon} from '@heroicons/vue/24/solid'

export default {
  components: {
    CheckCircleIcon
  },

  methods: {
    onLoadLanguages() {
      this.$api.get('/languages').then((result) => {
        console.log(result.data)
        this.languages = result.data;
      }).finally(() => {
        this.loading = false;
      })
    },

    onChangeLanguage(language) {
      console.log(language)
    }
  },

  data() {
    return {
      loading: true,
      languages: [],
    }
  },

  mounted() {
    this.onLoadLanguages()
  }
}
</script>