<template>
  <div>
    <input
        id="default-range"
        type="range"
        class="w-full h-1 bg-white/25 rounded-lg appearance-none cursor-pointer"
        :value="modelValue"
        @input="updateValue($event.target.value)"
        min="1"
        max="100"
        step="1"
    >
  </div>
</template>

<script>
import { initFlowbite } from 'flowbite'

export default {
  name: 'SingleRangeSlider',

  props: {
    modelValue: {
      type: Number,
      default: 5
    }
  },

  emits: ['update:modelValue'],

  mounted() {
    initFlowbite()
  },

  methods: {
    updateValue(val) {
      this.$emit('update:modelValue', Number(val))
    }
  }
}
</script>

<style scoped>
/* Ползунок (thumb) */
#default-range::-webkit-slider-thumb {
  -webkit-appearance: none; /* убираем системные стили */
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #e02424; /* красный-500 (Tailwind) */
  cursor: pointer;
}
#default-range::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border: none;
  border-radius: 50%;
  background: #e02424;
  cursor: pointer;
}

/* При фокусе (например, если хочется подсветить ползунок) */
#default-range:focus::-webkit-slider-thumb {
  outline: 2px solid #e02424; /* красный-600 */
}
#default-range:focus::-moz-range-thumb {
  outline: 2px solid #e02424;
}
</style>
