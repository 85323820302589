import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SettingsView from '../views/SettingsView.vue'
import LanguageView from '../views/settings/LanguageView.vue'
import SignalsView from '../views/settings/SignalsView.vue'
import PumpAndDumpView from '../views/settings/PumpAndDumpView.vue'
import LiquidationView from '../views/LiquidationView.vue'
import PumpDumpView from '../views/PumpDumpView.vue'
import OpenInterestView from '../views/OpenInterestView.vue'
import OpenInterestSettingView from '../views/settings/OpenInterestSettingView.vue'
import LiquidationSettingView from '../views/settings/LiquidationsView.vue'
import NotificationsView from '../views/settings/NotificationsView.vue'
import AnalyzeSignalView from '../views/ai/AnalyzeSignalView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pump',
    name: 'pump',
    component: PumpDumpView
  },
  {
    path: '/analyze/:id',
    name: 'analyze',
    component: AnalyzeSignalView
  },
  {
    path: '/oi',
    name: 'oi',
    component: OpenInterestView
  },
  {
    path: '/liquidations',
    name: 'liquidations',
    component: LiquidationView
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView
  },
  {
    path: '/settings/language',
    name: 'language',
    component: LanguageView
  },
  {
    path: '/settings/signals',
    name: 'signals',
    component: SignalsView
  },
  {
    path: '/settings/pump-and-dump',
    name: 'pump-and-dump',
    component: PumpAndDumpView
  },
  {
    path: '/settings/oi',
    name: 'setting-oi',
    component: OpenInterestSettingView
  },
  {
    path: '/settings/liquidations',
    name: 'setting-liquidation',
    component: LiquidationSettingView
  },
  {
    path: '/settings/notifications',
    name: 'setting-notifications',
    component: NotificationsView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
