<template>
  <div class="h-full flex flex-col pb-10">
    <div class="w-full px-6">
      <div class="mt-4 text-white text-3xl font-bold">
        Liquidations
      </div>
      <div class="mt-5 border-b border-white/20 pb-8">
        <div class="mt-5">
          <div class="text-sm/6 text-white/70">
            Minimum liquidation amount
          </div>

          <div class="mt-1 relative">
            <div class="absolute right-3 top-2.5 text-base text-white/70">$</div>
            <input
                v-model="form.liq_min_amount"
                type="number"
                inputmode="decimal"
                step="any"
                class="block w-full rounded-xl custom-bg-gray px-3 py-2 text-base text-white/70 outline outline-1 -outline-offset-1 outline-white/15 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-red-600 ring-0 focus:ring-0 ring-opacity-0 bottom-0"
            />
          </div>
        </div>
      </div>

      <button
          @click="onUpdateChanges"
          type="button"
          class="flex w-full bg-red-600 items-center justify-center rounded-xl px-3 py-3 font-semibold text-white shadow-sm"
          :class="[formProcessing ? 'opacity-50 pointer-events-none' : '']"
      >
        <template v-if="formProcessing">
          <BtnSpinner />
        </template>
        Update changes
      </button>

      <div v-if="successMessage.length > 0" class="text-green-500 flex items-center mt-3 text-sm justify-center">
        <CheckCircleIcon class="w-4 h-4 mr-2" />
        {{ successMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import BtnSpinner from "@/components/base/BtnSpinner.vue";
import { CheckCircleIcon } from '@heroicons/vue/16/solid'

export default {
  components: {
    BtnSpinner,
    CheckCircleIcon,
  },

  methods: {
    onUpdateChanges() {
      this.formProcessing = true

      this.$api.post('/user/update-settings', this.form)
        .then(() => {
          this.successMessage = 'Settings updated successfully'
        })
        .catch(() => {
          alert('Failed to update settings')
        })
        .finally(() => {
          this.formProcessing = false
        })
    }
  },

  data() {
    return {
      loading: true,
      formProcessing: false,

      successMessage: '',

      form: {
        section: 'liq',
        liq_min_amount: 0,
      },
    }
  },

  mounted() {
    console.log('user settings', this.$user)

    this.$api.get('/user').then(response => {
      this.form = {
        section: 'liq',
        liq_min_amount: response.data.liq_min_amount
      }
    }).finally(() => {
      this.loading = false
    })
  }
}
</script>
